import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import Sidebarmobile from "../../layouts/SidebarMobile";
import Asidebar from "../../layouts/Asidebar";
import Header from "../../layouts/Header";
import HeadeTopbar from "../../layouts/HeaderTopbar";
import SubHeader from "../../layouts/SubHeader";
import Footer from "../../layouts/Footer";
import { editSpaceType } from "../../../actions/spaceTypeAction";
import swal from "sweetalert2";

const Toast = swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
class EditSpaceType extends Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      errors: {},
      spaceType: "",
      unit: "",
      min: "",
      max: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  //set all the values to input fields
  componentDidMount() {
    var editResult = {};
    if (!localStorage.editspacetype) {
      this.props.history.push(`/admin/editspacetype`);
    } else {
      editResult = JSON.parse(localStorage.getItem("editspacetype"));
      console.log("space_edit", editResult);
    }
    this.setState({
      _id: editResult._id,
      spaceType: editResult.type,
      unit: editResult?.rt60?.unit,
      min:editResult?.rt60?.min,
      max: editResult?.rt60?.max,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.spacetype.editspacetype !== this.props.spacetype.editspacetype) {
      Toast.fire({
        type: "success",
        title: "Space type Edited Successfully",
      }).then((navigate) => {
        this.props.history.push("/admin/listspacetype");
      });
    }
    if (nextProps.errors !== this.props.errors) {
      Toast.fire({
        type: "error",
        title: "Check all the fields",
      });
      this.setState({ errors: nextProps.errors });
    }
  }

  //onchange state value for admin
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  //submit data to server
  onSubmit(e) {
    e.preventDefault();
    const editSpaceTypeData = {
        _id:this.state._id,
        type:this.state.spaceType,
          rt60: {
              unit:this.state.unit,
              min:parseFloat(this.state.min),
              max:parseFloat(this.state.max),
          },
          
      }
    this.props.editSpaceType(editSpaceTypeData);
  }
  //Reset all statevalues
  onReset() {
    this.setState({
      errors: {},
      _id: "",
      spaceType: "",
      unit: "",
      min:"",
      max: "",
    });
  }

  render() {
    const { errors } = this.state;
    const { spacetypeloading } = this.props.spacetype;

    return (
      <div>
        <Sidebarmobile />
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            {/* begin:: Aside */}
            <Asidebar />
            {/* end:: Aside */}
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              {/* begin:: Header */}
              <div
                id="kt_header"
                className="kt-header kt-grid__item  kt-header--fixed "
              >
                {/* begin:: Header Menu */}
                <Header />
                {/* end:: Header Menu */}
                {/* begin:: Header Topbar */}
                <HeadeTopbar />
                {/* end:: Header Topbar */}
              </div>
              {/* end:: Header */}
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                {/* begin:: Subheader */}
                <SubHeader
                  first="Home"
                  second="List Space Type"
                  link="/listspacetype"
                  third="Edit Space Type"
                />
                {/* end:: Subheader */}
                {/* begin:: Content */}
                <div
                  className="kt-content  kt-grid__item kt-grid__item--fluid"
                  id="kt_content"
                >
                  <div className="kt-portlet">
                    <div className="kt-portlet__head">
                      <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">{localStorage.editspacetype? "Edit Space Type":"Add Space Type"}</h3>
                      </div>
                    </div>
                    {/*begin::Form*/}
                    <form
                      className="kt-form kt-form--fit kt-form--label-right"
                      onSubmit={this.onSubmit}
                    >
                      <div className="kt-portlet__body">
                        <div className="form-group row">
                          <label className="col-lg-2 col-form-label">
                            Type:
                          </label>
                          <div className="col-lg-3">
                            <input
                              type="text"
                              name="spaceType"
                              onChange={this.onChange}
                              value={this.state.spaceType}
                              className="form-control"
                              placeholder=""
                            />
                            <span className="form-text text-danger">
                              {errors.spaceType}
                            </span>
                          </div>

                          <label className="col-lg-2 col-form-label">
                            Unit:
                          </label>
                          <div className="col-lg-3">
                            <input
                              type="text"
                              name="unit"
                              onChange={this.onChange}
                              value={this.state.unit}
                              className="form-control"
                              placeholder=""
                            />
                            <span className="form-text text-danger">
                              {errors.unit}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-2 col-form-label">
                            Min:
                          </label>
                          <div className="col-lg-3">
                            <input
                              type="text"
                              name="min"
                              onChange={this.onChange}
                              value={this.state.min}
                              className="form-control"
                              placeholder=""
                            />
                            <span className="form-text text-danger">
                              {errors.min}
                            </span>
                          </div>

                          <label className="col-lg-2 col-form-label">
                            Max:
                          </label>
                          <div className="col-lg-3">
                            <input
                              type="text"
                              name="max"
                              onChange={this.onChange}
                              value={this.state.max}
                              className="form-control"
                              placeholder=""
                            />
                            <span className="form-text text-danger">
                              {errors.max}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="kt-portlet__foot kt-portlet__foot--fit-x">
                        <div className="kt-form__actions">
                          <div className="row">
                            <div className="col-lg-3 d-flex justify-content-around">
                              <button
                                type="submit"
                                className={`btn btn-success ${
                                    spacetypeloading
                                    ? "kt-spinner kt-spinner--sm kt-spinner--light"
                                    : ""
                                }`}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                onClick={this.onReset}
                                className="btn btn-secondary"
                              >
                                Clear
                              </button>
                            </div>
                            <div className="col-lg-10" />
                          </div>
                        </div>
                      </div>
                    </form>
                    {/*end::Form*/}
                  </div>

                  {/*end::Portlet*/}

                  {/*end::Portlet datatable*/}
                </div>
                {/* end:: Content */}
              </div>
              {/* begin:: Footer */}
              <Footer />
              {/* end:: Footer */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditSpaceType.propTypes = {
  auth: PropTypes.object.isRequired,
  editSpaceType: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  spacetype: state.spacetype,
});

export default connect(mapStateToProps, { editSpaceType })(EditSpaceType);
