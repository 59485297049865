import React, { Component } from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import Sidebarmobile from '../../layouts/SidebarMobile';
import Asidebar from '../../layouts/Asidebar';
import Header from '../../layouts/Header';
import HeadeTopbar from '../../layouts/HeaderTopbar';
import SubHeader from '../../layouts/SubHeader';
import Footer from '../../layouts/Footer';
import swal from 'sweetalert2';
import { addWallType } from '../../../actions/walltypeAction';
const Toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

class AddWallType extends Component {
  constructor(){
    super();
    this.state={
       errors:{},
       wallType:"",
       frequency125Rt:"",
       frequency250Rt:"",
       frequency500Rt:"",
       frequency1000Rt:"",
       frequency2000Rt:"",
       frequency4000Rt:"",
       nrc:""
       
    }   
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
 
}


componentWillReceiveProps(nextProps){
 
    if(nextProps?.walltype?.addwalltype !== this.props?.walltype?.addwalltype){
        Toast.fire({
            type: 'success',
            title: 'Wall type Added Successfully',
          })
          this.onReset();
    }
    if(nextProps.errors !== this.props.errors){
        Toast.fire({
            type: 'error',
            title: 'Check all the fields',
          })
        this.setState({errors:nextProps.errors});
    }
}


//onchange state value for admin
onChange(e){
    this.setState({[e.target.name]:e.target.value})
    
}

onChangeType = (e) => {
  this.setState({ wallType: e.target.value });
}

//submit data to server
onSubmit = async  (e) =>{
    this.setState({errors:{}})
    e.preventDefault();
    const wallData ={
        type: this.state.wallType,
        frequency: [
          // {
          //   frequency: 125,
          //   rt: parseFloat(this.state.frequency125Rt)
          // },
          // {
          //   frequency: 250,
          //   rt: parseFloat(this.state.frequency250Rt)
          // },
          // {
          //   frequency: 500,
          //   rt: parseFloat(this.state.frequency500Rt)
          // },
          // {
          //   frequency: 1000,
          //   rt: parseFloat(this.state.frequency1000Rt)
          // },
          // {
          //   frequency: 2000,
          //   rt: parseFloat(this.state.frequency2000Rt)
          // },
          // {
          //   frequency: 4000,
          //   rt: parseFloat(this.state.frequency4000Rt)
          // }
          {
            frequency: 125,
            rt: parseFloat(this.state.frequency125Rt)
          },
          {
            frequency: 250,
            rt: parseFloat(this.state.frequency125Rt)
          },
          {
            frequency: 500,
            rt: parseFloat(this.state.frequency125Rt)
          },
          {
            frequency: 1000,
            rt: parseFloat(this.state.frequency125Rt)
          },
          {
            frequency: 2000,
            rt: parseFloat(this.state.frequency125Rt)
          },
          {
            frequency: 4000,
            rt: parseFloat(this.state.frequency125Rt)
          }
        ],
    }
    this.props.addWallType(wallData);
}
//Reset all statevalues
onReset(){
    this.setState({
        errors:{},
        wallType:"",
        frequency125Rt:"",
        frequency250Rt:"",
        frequency500Rt:"",
        frequency1000Rt:"",
        frequency2000Rt:"",
        frequency4000Rt:"",
    })
}

render() {
    const {errors} = this.state;
    const {walltypeloading} = this.props.walltype;


   return (
      <div>
     <Sidebarmobile/>
      <div className="kt-grid kt-grid--hor kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <Asidebar/>
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
            <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed ">
               <Header />
             <HeadeTopbar />
            </div>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
              <SubHeader first="Home" second="Add WallType" third=""/>
              <div className="kt-content  kt-grid__item kt-grid__item--fluid" id="kt_content">
                    <div className="kt-portlet">
                     <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                            Add Wall Type
                            </h3>
                        </div>
                    </div>
                    <form className="kt-form kt-form--fit kt-form--label-right" onSubmit={this.onSubmit}>
                        <div className="kt-portlet__body">
                            <div className="form-group row">
                            <label className="col-lg-2 col-form-label">Type:</label>
                            <div className="col-lg-3">
                                <input type="text" name="wallType" onChange={this.onChangeType} value={this.state.wallType} className="form-control" placeholder="" />
                                <span className="form-text text-danger">{errors.wallType}</span>
                            </div>

                            <label className="col-lg-2 col-form-label">Average NRC:</label>
                            <div className="col-lg-3">
                                <input type="number" name="frequency125Rt" onChange={this.onChange} value={this.state.frequency125Rt} className="form-control" placeholder=""  />
                                <span className="form-text text-danger">{errors.frequency125Rt}</span>
                            </div>

                            </div>
                            <div className="form-group row">
                            <label className="col-lg-2 col-form-label">frequency(125):</label>
                            <div className="col-lg-3">
                                <input type="number" name="frequency125Rt" onChange={this.onChange} 
                                
                                // value={this.state.frequency125Rt}
                                value={null}
                                className="form-control" placeholder=""  disabled/>
                                <span className="form-text text-danger">{errors.frequency125Rt}</span>
                            </div>

                            <label className="col-lg-2 col-form-label">frequency(250):</label>
                            <div className="col-lg-3">
                                <input type="number" name="frequency250Rt" onChange={this.onChange}
                                 value={this.state.frequency250Rt} 
                                 
                                 className="form-control" placeholder="" disabled />
                                <span className="form-text text-danger">{errors.frequency250Rt}</span>
                            </div>
                           
                            </div>
                            <div className="form-group row">
                            <label className="col-lg-2 col-form-label">frequency(500):</label>
                            <div className="col-lg-3">
                                <input type="number" name="frequency500Rt" onChange={this.onChange} value={this.state.frequency500Rt} className="form-control" placeholder="" disabled />
                                <span className="form-text text-danger">{errors.frequency500Rt}</span>
                            </div>

                            <label className="col-lg-2 col-form-label">frequency(1000):</label>
                            <div className="col-lg-3">
                                <input type="number" name="frequency1000Rt" onChange={this.onChange} value={this.state.frequency1000Rt} className="form-control" placeholder="" disabled />
                                <span className="form-text text-danger">{errors.frequency1000Rt}</span>
                            </div>
                           
                            </div>
                            <div className="form-group row">
                            <label className="col-lg-2 col-form-label">frequency(2000):</label>
                            <div className="col-lg-3">
                                <input type="number" name="frequency2000Rt" onChange={this.onChange} value={this.state.frequency2000Rt} className="form-control" placeholder="" disabled />
                                <span className="form-text text-danger">{errors.frequency2000Rt}</span>
                            </div>

                            <label className="col-lg-2 col-form-label">frequency(4000):</label>
                            <div className="col-lg-3">
                                <input type="number" name="frequency4000Rt" onChange={this.onChange} value={this.state.frequency4000Rt} className="form-control" placeholder="" disabled/>
                                <span className="form-text text-danger">{errors.frequency4000Rt}</span>
                            </div>
                           
                            </div>
                           
                           
                        </div>
                        <div className="kt-portlet__foot kt-portlet__foot--fit-x">
                            <div className="kt-form__actions">
                            <div className="row">
                            <div className="col-lg-3 d-flex justify-content-around">
                                <button type="submit" className={`btn btn-success ${walltypeloading ?  'kt-spinner kt-spinner--sm kt-spinner--light':''}`}>Submit</button>
                                <button  type="button" onClick={this.onReset} className="btn btn-secondary">Clear</button>
                            </div>
                            <div className="col-lg-10" />
                                
                            </div>
                            </div>
                        </div>
                    </form>
                    {/*end::Form*/}
                </div>
                
                {/*end::Portlet*/}

                {/*end::Portlet datatable*/}
             </div>
              {/* end:: Content */}
            </div>
            {/* begin:: Footer */}
            <Footer/>
            {/* end:: Footer */}
          </div>
        </div>
      </div>
    </div>
    )
  }
}

AddWallType.propTypes ={
    auth: PropTypes.object.isRequired,
    addWallType: PropTypes.func.isRequired,
}
  
const mapStateToProps = (state)=>({
    auth : state.auth,
    errors: state.errors,
    walltype: state.walltype
});
  
export default connect(mapStateToProps,{addWallType})(AddWallType);