import axios from 'axios';
import {
  GET_ERRORS,
  LIST_CEILINGTYPE,
  EDIT_CEILINGTYPE,
  DELETE_CEILINGTYPE,
  CEILINGTYPE_LOADING,
  CEILINGTYPE_STOPLOADING,
  ADD_CEILINGTYPE,
} from './types';

// Get all slider
export const listCeilingType= () => dispatch => {
  dispatch(setCeilingTypeLoading());
  axios
    .get('/api/ceilingtype')
    .then(res =>{
      console.log( "Ceiling Result",res.data)
      dispatch({
        type: LIST_CEILINGTYPE,
        payload:res.data
      })
    })
    .catch(err =>{
      console.log("err data",err)
      dispatch({
        type: GET_ERRORS,
        payload:err.response.data
      })

    });
};

// Create slider
export const addCeilingType= (ceilingData) => dispatch => {
    dispatch(setCeilingTypeLoading());
    axios
    .post('/api/ceilingtype',ceilingData)
    .then(res =>
        dispatch({
            type: ADD_CEILINGTYPE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopCeilingTypeLoading());
    });
};

// Edit slider
export const editCeilingtype= (editCeilingTypeData) => dispatch => {
    dispatch(setCeilingTypeLoading());
    axios
    .put(`/api/ceilingtype/${editCeilingTypeData._id}`,editCeilingTypeData)
    .then(res =>
        dispatch({
            type: EDIT_CEILINGTYPE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopCeilingTypeLoading());
    });
};
// delete slider
export const deleteCeilingtype= (deleteData) => dispatch => {
    dispatch(setCeilingTypeLoading());
    axios
    .delete(`/api/ceilingtype/${deleteData._id}`,deleteData)
    .then(res =>
        dispatch({
            type: DELETE_CEILINGTYPE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopCeilingTypeLoading());
    });
};
// Slider loading
export const setCeilingTypeLoading = () => {
    return {
      type: CEILINGTYPE_LOADING
    };
  };
  export const stopCeilingTypeLoading = () => {
    return {
      type: CEILINGTYPE_STOPLOADING
    };
  };