import React from 'react';
import date from 'date-and-time';
var now = new Date();
function SubHeader(props) {
  
  return  <div className="kt-subheader   kt-grid__item" id="kt_subheader">
  <div className="kt-subheader__main">
    <span className="kt-subheader__separator kt-hidden" />
    <div className="kt-subheader__breadcrumbs">
      <a href="/dashboard" className="kt-subheader__breadcrumbs-home"><i className="flaticon2-shelter" /></a>
      <span className="kt-subheader__breadcrumbs-separator" />
      <a href className="kt-subheader__breadcrumbs-link">
        {props.first} </a>
      <span className="kt-subheader__breadcrumbs-separator" />
      <a href className="kt-subheader__breadcrumbs-link">
      {props.second} </a>
      <span className="kt-subheader__breadcrumbs-separator" />
      <a href className="kt-subheader__breadcrumbs-link">
      {props.third} </a>
      {/* <span class="kt-subheader__breadcrumbs-link kt-subheader__breadcrumbs-link--active">Active link</span> */}
    </div>
  </div>
  <div className="kt-subheader__toolbar">
    <div className="kt-subheader__wrapper">
      <a href="#" className="btn kt-subheader__btn-daterange" id="kt_dashboard_daterangepicker"  title="Have a Nice Day" data-placement="left">
        <span className="kt-subheader__btn-daterange-title" id="kt_dashboard_daterangepicker_title">Today</span>&nbsp;
        <span className="kt-subheader__btn-daterange-date" id="kt_dashboard_daterangepicker_date">{date.format(now, 'MMM DD YYYY')}</span>
        {/*<i class="flaticon2-calendar-1"></i>*/}
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--sm">
          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
            <rect id="bound" x={0} y={0} width={24} height={24} />
            <path d="M4.875,20.75 C4.63541667,20.75 4.39583333,20.6541667 4.20416667,20.4625 L2.2875,18.5458333 C1.90416667,18.1625 1.90416667,17.5875 2.2875,17.2041667 C2.67083333,16.8208333 3.29375,16.8208333 3.62916667,17.2041667 L4.875,18.45 L8.0375,15.2875 C8.42083333,14.9041667 8.99583333,14.9041667 9.37916667,15.2875 C9.7625,15.6708333 9.7625,16.2458333 9.37916667,16.6291667 L5.54583333,20.4625 C5.35416667,20.6541667 5.11458333,20.75 4.875,20.75 Z" id="check" fill="#000000" fillRule="nonzero" opacity="0.3" />
            <path d="M2,11.8650466 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.23590829,11 3.04485894,11.3127315 2,11.8650466 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 L15,9 C15.5522847,9 16,8.55228475 16,8 C16,7.44771525 15.5522847,7 15,7 L6,7 Z" id="Combined-Shape" fill="#000000" />
          </g>
        </svg> </a>
      {/* <div className="dropdown dropdown-inline" data-toggle="kt-tooltip" title="Quick actions" data-placement="left">
        <a href="#" className="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-svg-icon--success kt-svg-icon--md">
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
              <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
              <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" id="Combined-Shape" fill="#000000" fillRule="nonzero" opacity="0.3" />
              <path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" id="Combined-Shape" fill="#000000" />
            </g>
          </svg>
    
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <ul className="kt-nav">
            <li className="kt-nav__section kt-nav__section--first">
              <span className="kt-nav__section-text">Add new:</span>
            </li>
            <li className="kt-nav__item">
              <a href="/addlocation" className="kt-nav__link">
                <i className="kt-nav__link-icon flaticon2-graph-1" />
                <span className="kt-nav__link-text">Location</span>
              </a>
            </li>
            <li className="kt-nav__item">
              <a href="/addagents" className="kt-nav__link">
                <i className="kt-nav__link-icon flaticon2-calendar-4" />
                <span className="kt-nav__link-text">Agent</span>
              </a>
            </li>
            <li className="kt-nav__item">
              <a href="/addshops" className="kt-nav__link">
                <i className="kt-nav__link-icon flaticon2-layers-1" />
                <span className="kt-nav__link-text">Shop</span>
              </a>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  </div>
</div>

 

  
}

export default SubHeader;
