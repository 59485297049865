import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import Sidebarmobile from "../../layouts/SidebarMobile";
import Asidebar from "../../layouts/Asidebar";
import Header from "../../layouts/Header";
import HeadeTopbar from "../../layouts/HeaderTopbar";
import SubHeader from "../../layouts/SubHeader";
import Footer from "../../layouts/Footer";
import { editExistingSurface } from "../../../actions/existingSurfaceAction";
import swal from "sweetalert2";

const Toast = swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
class EditExistingSurface extends Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      errors: {},
      existingSurfaceTypeVal: "",
      NRC: "",
   
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  //set all the values to input fields
  componentDidMount() {
    var editResult = {};
    if (!localStorage.editexistingsurface) {
      this.props.history.push(`/admin/editexistingsurface`);
    } else {
      editResult = JSON.parse(localStorage.getItem("editexistingsurface"));
      console.log("existingsurface_edit", editResult);
    }
    this.setState({
      _id: editResult._id,
      existingSurfaceTypeVal: editResult.type,
      NRC: editResult?.NRC,
    
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.existingsurface.editexistingsurface !== this.props.existingsurface.editexistingsurface) {
      Toast.fire({
        type: "success",
        title: "Existing Surface type Edited Successfully",
      }).then((navigate) => {
        this.props.history.push("/admin/listexistingsurface");
      });
    }
    if (nextProps.errors !== this.props.errors) {
      Toast.fire({
        type: "error",
        title: "Check all the fields",
      });
      this.setState({ errors: nextProps.errors });
    }
  }

  //onchange state value for admin
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  //submit data to server
  onSubmit(e) {
    e.preventDefault();
    const editExistingSurfaceData = {
        _id:this.state._id,
        type:this.state.existingSurfaceTypeVal,
        NRC:parseFloat(this.state.NRC)
          
      }
    this.props.editExistingSurface(editExistingSurfaceData);
  }
  //Reset all statevalues
  onReset() {
    this.setState({
      errors: {},
      _id: "",
      existingSurfaceTypeVal: "",
      NRC: "",
     
    });
  }

  render() {
    const { errors } = this.state;
    const { existingsurfaceloading } = this.props.existingsurface;

    return (
      <div>
        <Sidebarmobile />
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            {/* begin:: Aside */}
            <Asidebar />
            {/* end:: Aside */}
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              {/* begin:: Header */}
              <div
                id="kt_header"
                className="kt-header kt-grid__item  kt-header--fixed "
              >
                {/* begin:: Header Menu */}
                <Header />
                {/* end:: Header Menu */}
                {/* begin:: Header Topbar */}
                <HeadeTopbar />
                {/* end:: Header Topbar */}
              </div>
              {/* end:: Header */}
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                {/* begin:: Subheader */}
                <SubHeader
                  first="Home"
                  second="List Existing Surface"
                  link="/listexistingsurface"
                  third="Edit Existing Surface Type"
                />
                {/* end:: Subheader */}
                {/* begin:: Content */}
                <div
                  className="kt-content  kt-grid__item kt-grid__item--fluid"
                  id="kt_content"
                >
                  <div className="kt-portlet">
                    <div className="kt-portlet__head">
                      <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">{localStorage.editexistingsurface? "Edit Existing Surface":"Add Existing Surface"}</h3>
                      </div>
                    </div>
                    {/*begin::Form*/}
                    <form
                      className="kt-form kt-form--fit kt-form--label-right"
                      onSubmit={this.onSubmit}
                    >
                      <div className="kt-portlet__body">
                        <div className="form-group row">
                          <label className="col-lg-2 col-form-label">
                            Type:
                          </label>
                          <div className="col-lg-3">
                            <input
                              type="text"
                              name="existingSurfaceTypeVal"
                              onChange={this.onChange}
                              value={this.state.existingSurfaceTypeVal}
                              className="form-control"
                              placeholder=""
                            />
                            <span className="form-text text-danger">
                              {errors.existingSurfaceTypeVal}
                            </span>
                          </div>

                          <label className="col-lg-2 col-form-label">
                            NRC:
                          </label>
                          <div className="col-lg-3">
                            <input
                              type="text"
                              name="NRC"
                              onChange={this.onChange}
                              value={this.state.NRC}
                              className="form-control"
                              placeholder=""
                            />
                            <span className="form-text text-danger">
                              {errors.nrc}
                            </span>
                          </div>
                        </div>

                      
                      </div>
                      <div className="kt-portlet__foot kt-portlet__foot--fit-x">
                        <div className="kt-form__actions">
                          <div className="row">
                            <div className="col-lg-3 d-flex justify-content-around">
                              <button
                                type="submit"
                                className={`btn btn-success ${
                                  existingsurfaceloading
                                    ? "kt-spinner kt-spinner--sm kt-spinner--light"
                                    : ""
                                }`}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                onClick={this.onReset}
                                className="btn btn-secondary"
                              >
                                Clear
                              </button>
                            </div>
                            <div className="col-lg-10" />
                          </div>
                        </div>
                      </div>
                    </form>
                    {/*end::Form*/}
                  </div>

                  {/*end::Portlet*/}

                  {/*end::Portlet datatable*/}
                </div>
                {/* end:: Content */}
              </div>
              {/* begin:: Footer */}
              <Footer />
              {/* end:: Footer */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditExistingSurface.propTypes = {
  auth: PropTypes.object.isRequired,
  editExistingSurface: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  existingsurface: state.existingsurface,
});

export default connect(mapStateToProps, { editExistingSurface })(EditExistingSurface);
