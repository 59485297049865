import {
  ADD_CEILINGTYPE,
  DELETE_CEILINGTYPE,
  EDIT_CEILINGTYPE,
  LIST_CEILINGTYPE,
  CEILINGTYPE_LOADING,
  CEILINGTYPE_STOPLOADING,
} from "../actions/types";

const initialState = {
  addceilingtype: null,
  ceilingtypeloading: false,
  editceilingtypeRec: null,
  deleteceilingtypeRec: null,
  listceilingtypeRec: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CEILINGTYPE_LOADING:
      return {
        ...state,
        ceilingtypeloading: true,
      };
    case CEILINGTYPE_STOPLOADING:
      return {
        ...state,
        ceilingtypeloading: false,
      };

    case ADD_CEILINGTYPE:
      return {
        ...state,
        addceilingtype: action.payload,
        ceilingtypeloading: false,
      };
    case EDIT_CEILINGTYPE:
      return {
        ...state,
        editceilingtypeRec: action.payload,
        ceilingtypeloading: false,
      };
    case LIST_CEILINGTYPE:
      return {
        ...state,
        listceilingtypeRec: action.payload,
        ceilingtypeloading: false,
      };
    case DELETE_CEILINGTYPE:
      return {
        ...state,
        deleteceilingtypeRec: action.payload,
        ceilingtypeloading: false,
      };
    default:
      return state;
  }
}
