import axios from 'axios';
import {
  SPACETYPE_LOADING,
  SPACETYPE_STOPLOADING,
  ADD_SPACETYPE,
  EDIT_SPACETYPE,
  DELETE_SPACETYPE,
  LIST_SPACETYPE,
  GET_ERRORS,
} from './types';

//List space type
export const listSpaceType= () => dispatch => {
  dispatch(setSpaceTypeLoading());
  axios
    .get('/api/spacetype')
    .then(res =>{
      console.log("listspacetype", res);
      dispatch({
        type: LIST_SPACETYPE,
        payload:res.data
      })
    })
    .catch(err =>{
      console.log("err data",err)
      dispatch({
        type: GET_ERRORS,
        payload:err.response.data
      })

    });
};

// add space type
export const addSpaceType= (spaceData) => dispatch => {
    dispatch(setSpaceTypeLoading());
    axios
    .post('/api/spacetype',spaceData)
    .then(res =>
        dispatch({
            type: ADD_SPACETYPE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopSpaceTypeLoading());
    });
};

// Edit space type
export const editSpaceType= (editSpaceTypeData) => dispatch => {
    dispatch(setSpaceTypeLoading());
    axios
    .put(`/api/spacetype/${editSpaceTypeData?._id}`,editSpaceTypeData)
    .then(res =>
        dispatch({
            type: EDIT_SPACETYPE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopSpaceTypeLoading());
    });
};
// delete space type
export const deleteSpaceType= (deleteData) => dispatch => {
    dispatch(setSpaceTypeLoading());
    axios
    .delete(`/api/spacetype/${deleteData._id}`,deleteData)
    .then(res =>
        dispatch({
            type: DELETE_SPACETYPE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopSpaceTypeLoading());
    });
};
// space type loading
export const setSpaceTypeLoading = () => {
    return {
      type: SPACETYPE_LOADING
    };
  };
  export const stopSpaceTypeLoading = () => {
    return {
      type: SPACETYPE_STOPLOADING
    };
  };