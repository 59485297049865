import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import Sidebarmobile from "../../layouts/SidebarMobile";
import Asidebar from "../../layouts/Asidebar";
import Header from "../../layouts/Header";
import HeadeTopbar from "../../layouts/HeaderTopbar";
import SubHeader from "../../layouts/SubHeader";
import Footer from "../../layouts/Footer";
import { listSpaceType, deleteSpaceType } from "../../../actions/spaceTypeAction";
import swal from "sweetalert2";
import SearchInput, { createFilter } from "react-search-input";
import { checkPermission } from "../../common/MenuList";
const KEYS_TO_FILTERS = ["type"];
const Toast = swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1000,
});

class ListSpaceType extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      searchTerm: "",
    };
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.searchUpdated = this.searchUpdated.bind(this);
  }
  componentDidMount() {
    this.props.listSpaceType();
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }
  //calls when edit button is clicked
  onEditClick(res) {
    localStorage.setItem("editspacetype", JSON.stringify(res));
    this.props.history.push("editspacetype");
  }
  //calls when delete button is clicked
  onDeleteClick(_id) {
    const deleteData = {
      _id: _id,
    };
    this.props.deleteSpaceType(deleteData);
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.spacetype.deletespacetype !== this.props.spacetype.deletespacetype
    ) {
      Toast.fire({
        type: "success",
        title: "Space Type Deleted Successfully",
      }).then((getResult) => {
        this.props.listSpaceType();
      });
    }
    if (nextProps.errors !== this.props.errors) {
      Toast.fire({
        type: "error",
        title: "Check all the fields",
      });
      this.setState({ errors: nextProps.errors });
    }
  }

  render() {
    const { listspacetype } = this.props.spacetype;
    var tableResult;
    if (listspacetype == null) {
      tableResult = (
        <tr>
          <td colSpan={4} className="text-center">
            Loading.....
          </td>
        </tr>
      );
    } else {
      if (Object.keys(listspacetype).length > 0) {
        var filterData = listspacetype.filter(
          createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
        );

        tableResult = filterData.map((result) => {
          return (
            <tr key={result._id}>
              <td>{result?.type}</td>
              <td>{result?.rt60?.unit}</td>
              <td>{result?.rt60?.min}</td>
              <td>{result?.rt60?.max}</td>
              <td>
                {checkPermission(this.props.auth, "ADMIN", "UPDATE") && (
                  <button
                    className="btn btn-link"
                    onClick={() => this.onEditClick(result)}
                  >
                    <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                      Edit
                    </span>
                  </button>
                )}
              </td>
              <td>
                {checkPermission(this.props.auth, "ADMIN", "DELETE") && (
                  <button
                    className="btn btn-link"
                    onClick={() => this.onDeleteClick(result?._id)}
                  >
                    <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--danger">
                      Delete
                    </span>
                  </button>
                )}
              </td>
            </tr>
          );
        });
      } else {
        tableResult = (
          <tr>
            <td colSpan={7} className="text-center">
              No Record Found.....
            </td>
          </tr>
        );
      }
    }

    return (
      <div>
        <Sidebarmobile />
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            {/* begin:: Aside */}
            <Asidebar />
            {/* end:: Aside */}
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              {/* begin:: Header */}
              <div
                id="kt_header"
                className="kt-header kt-grid__item  kt-header--fixed "
              >
                {/* begin:: Header Menu */}
                <Header />
                {/* end:: Header Menu */}
                {/* begin:: Header Topbar */}
                <HeadeTopbar />
                {/* end:: Header Topbar */}
              </div>
              {/* end:: Header */}
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                {/* begin:: Subheader */}
                <SubHeader first="Home" second="List Space Type" third="" />
                {/* end:: Subheader */}
                {/* begin:: Content */}
                <div
                  className="kt-content  kt-grid__item kt-grid__item--fluid"
                  id="kt_content"
                >
                  <div className="kt-portlet">
                    <div className="kt-portlet__head kt-portlet__head--lg">
                      <div className="kt-portlet__head-label">
                        <span className="kt-portlet__head-icon">
                          <i className="kt-font-brand flaticon2-line-chart" />
                        </span>
                        <h3 className="kt-portlet__head-title">List Space Type</h3>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <SearchInput
                        placeholder="Search"
                        onChange={this.searchUpdated}
                        className="search-input"
                      />
                    </div>
                    <div className="kt-portlet__body">
                      {/*begin: Datatable */}
                      <table
                        className="table table-striped table-bordered table-hover table-checkable"
                        id="kt_table_1"
                      >
                        <thead>
                          <tr>
                            <th>Space Type</th>
                            <th>Unit</th>
                            <th>Min</th>
                            <th>Max</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>{tableResult}</tbody>
                        <tfoot>
                          <tr>
                            <th>Space Type</th>
                            <th>Unit</th>
                            <th>Min</th>
                            <th>Max</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </tfoot>
                      </table>
                      {/*end: Datatable */}
                    </div>
                  </div>
                </div>
                {/* end:: Content */}
              </div>
              {/* begin:: Footer */}
              <Footer />
              {/* end:: Footer */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListSpaceType.propTypes = {
  auth: PropTypes.object.isRequired,
  listSpaceType: PropTypes.func.isRequired,
  deleteSpaceType: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  spacetype: state.spacetype,
});

export default connect(mapStateToProps, { listSpaceType, deleteSpaceType })(
  ListSpaceType
);
