import {
  ADD_FLOORTYPE,
  DELETE_FLOORTYPE,
  EDIT_FLOORTYPE,
  LIST_FLOORTYPE,
  FLOORTYPE_LOADING,
  FLOORTYPE_STOPLOADING,
} from "../actions/types";

const initialState = {
  addfloortype: null,
  floortypeloading: false,
  editfloortypeRec: null,
  deletefloortypeRec: null,
  listfloortypeRec: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FLOORTYPE_LOADING:
      return {
        ...state,
        floortypeloading: true,
      };
    case FLOORTYPE_STOPLOADING:
      return {
        ...state,
        floortypeloading: false,
      };

    case ADD_FLOORTYPE:
      return {
        ...state,
        addfloortype: action.payload,
        floortypeloading: false,
      };
    case EDIT_FLOORTYPE:
      return {
        ...state,
        editfloortypeRec: action.payload,
        floortypeloading: false,
      };
    case LIST_FLOORTYPE:
      return {
        ...state,
        listfloortypeRec: action.payload,
        floortypeloading: false,
      };
    case DELETE_FLOORTYPE:
      return {
        ...state,
        deletefloortypeRec: action.payload,
        floortypeloading: false,
      };
    default:
      return state;
  }
}
