import {
  EXISTINGSURFACE_LOADING,
  ADD_EXISTINGSURFACE,
  EDIT_EXISTINGSURFACE,
  DELETE_EXISTINGSURFACE,
  LIST_EXISTINGSURFACE,
  EXISTINGSURFACE_STOPLOADING,
} from "../actions/types";

const initialState = {
  addexistingsurface: null,
  existingsurfaceloading: false,
  editexistingsurface: null,
  deleteexistingsurface: null,
  listexistingsurface: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EXISTINGSURFACE_LOADING:
      return {
        ...state,
        existingsurfaceloading: true,
      };
    case EXISTINGSURFACE_STOPLOADING:
      return {
        ...state,
        existingsurfaceloading: false,
      };

    case ADD_EXISTINGSURFACE:
      return {
        ...state,
        addexistingsurface: action.payload,
        existingsurfaceloading: false,
      };
    case EDIT_EXISTINGSURFACE:
      return {
        ...state,
        editexistingsurface: action.payload,
        existingsurfaceloading: false,
      };
    case LIST_EXISTINGSURFACE:
      return {
        ...state,
        listexistingsurface: action.payload,
        existingsurfaceloading: false,
      };
    case DELETE_EXISTINGSURFACE:
      return {
        ...state,
        deleteexistingsurface: action.payload,
        existingsurfaceloading: false,
      };
    default:
      return state;
  }
}
