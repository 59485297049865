import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import Sidebarmobile from "../../layouts/SidebarMobile";
import Asidebar from "../../layouts/Asidebar";
import Header from "../../layouts/Header";
import HeadeTopbar from "../../layouts/HeaderTopbar";
import SubHeader from "../../layouts/SubHeader";
import Footer from "../../layouts/Footer";
import { addSubCategoryChild } from "../../../actions/subCategoryChildAction";
import { listCategory } from "../../../actions/categoryAction";
import { listSubCategoryOne } from "../../../actions/subCategoryAction";
import axios from "axios";
import swal from "sweetalert2";
import { Editor } from "@tinymce/tinymce-react";

const Toast = swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

class AddSubCategoryChildChild extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      uploadStatus: "",
      photoUrl: "",
      subCategoryChildName: "",
      isEnabled: "",
      categoryID: "",
      subcategoryID: "",
      storeID: "",
      content: "",
      banerContent: "",
      additionalContent: false,
      bannerTitle: "",

      bannerUploadStatus: "",
      bannerPhotoUrl: "",

      additionalDetails: [],
      faqList: [],
      faqQustion: "",
      faqAnswer: "",
      faq: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
  }
  componentDidMount() {
    this.props.listCategory();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.subCategoryChild.addsubCategoryChild !==
      this.props.subCategoryChild.addsubCategoryChild
    ) {
      Toast.fire({
        type: "success",
        title: "SubCategoryChild Added Successfully",
      });
      this.onReset();
    }
    if (nextProps.errors !== this.props.errors) {
      Toast.fire({
        type: "error",
        title: "Check all the fields",
      });
      this.setState({ errors: nextProps.errors });
    }
  }

  //onchange state value for subCategoryChild
  onChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }

    if (name === "categoryID" && value !== "") {
      this.props.listSubCategoryOne({
        categoryID: value,
        authorID: this.state.authorID,
      });
    }
  };

  //for banner
  bannerUploadImage = (e) => {
    var self = this;
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("filename", e.target.files[0].name);
    axios
      .post("/upload", data)
      .then(function (response) {
        self.setState({
          bannerPhotoUrl: response.data.file,
          bannerUploadStatus: "Uploaded Successfully",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //for upload image
  uploadImage(e) {
    var self = this;
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("filename", e.target.files[0].name);
    axios
      .post("/upload", data)
      .then(function (response) {
        self.setState({
          photoUrl: response.data.file,
          uploadStatus: "Uploaded SuccessFully",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //add details
  addDetail = () => {
    const { bannerTitle, banerContent, bannerPhotoUrl } = this.state;

    if (bannerTitle && banerContent && bannerPhotoUrl) {
      const newDetail = {
        image: bannerPhotoUrl,
        Title: bannerTitle,
        Description: banerContent,
      };
      this.setState({ addDetailClicked: true });
      this.setState((prevState) => ({
        additionalDetails: [...prevState.additionalDetails, newDetail],
        bannerTitle: "",
        banerContent: "",
        bannerPhotoUrl: "",
        bannerUploadStatus: "",
      }));
    }
  };
  removeDetail = (index) => {
    this.setState((prevState) => {
      const newDetails = [...prevState.additionalDetails];
      newDetails.splice(index, 1);
      return { additionalDetails: newDetails };
    });
  };

  //   faq add

  addFaqDetail = () => {
    const { faqQustion, faqAnswer } = this.state;

    if (faqQustion && faqAnswer) {
      const newDetail = {
        question: faqQustion,
        answer: faqAnswer,
      };

      this.setState({ addDetailClicked: true }); 
      this.setState((prevState) => ({
        faqList: [...prevState.faqList, newDetail],
        faqQustion: "",
        faqAnswer: "",
      }));
    }
  };

  removeFaqDetail = (index) => {
    this.setState((prevState) => {
      const newDetails = [...prevState.faqList];
      newDetails.splice(index, 1);
      return { faqList: newDetails };
    });
  };

  //submit data to server
  onSubmit(e) {
    this.setState({ errors: {} });
    e.preventDefault();

    if (this.state.addDetailClicked) {
      this.setState({ addDetailClicked: false });
      return;
    }
    const Data = {
      photoUrl: this.state.photoUrl,
      subCategoryChildName: this.state.subCategoryChildName,
      isEnabled: this.state.isEnabled, //visible,hidden
      storeID: this.state.storeID,
      categoryID: this.state.categoryID,
      subcategoryID: this.state.subcategoryID,

      Description: this.state.content,
      additionalContent: this.state.additionalContent,
      additionalDetails: this.state.additionalDetails,
      FAQ: this.state.faq,
      faqList: this.state.faqList,
    };
    console.log(Data, "valueCheck");
    this.props.addSubCategoryChild(Data);
  }
  //Reset all statevalues
  onReset() {
    this.setState({
      errors: {},
      uploadStatus: "",
      photoUrl: "",
      subCategoryChildName: "",
      isEnabled: "",
      categoryID: "",
      subcategoryID: "",
      content: "",
      banerContent: "",
      additionalContent: false,
      bannerTitle: "",
      bannerPhotoUrl: "",
      additionalDetails: [],
      faq: false,
      bannerUploadStatus: "",
      addDetailClicked: false,
      faqList: [],
      faqQustion: "",
      faqAnswer: "",
    });
  }

  render() {
    const { errors, additionalDetails, faqList } = this.state;
    const { subCategoryChildloading } = this.props.subCategoryChild;

    //SubCategoryChild list
    const { listcategory, categoryloading } = this.props.category;

    var optionResult = [];
    if (listcategory == null || categoryloading) {
      optionResult = <option value="">Loading...</option>;
    } else {
      if (Object.keys(listcategory).length > 0) {
        optionResult = listcategory.map((result) => {
          return <option value={result._id}>{result.categoryName}</option>;
        });
      } else {
        optionResult = <option value="">No Store Found...</option>;
      }
    }
    const { listsubCategory, subCategoryloading } = this.props.subCategory;

    var optionResultSubCategory = [];
    if (listsubCategory == null || subCategoryloading) {
      optionResultSubCategory = <option value="">Loading...</option>;
    } else {
      if (
        Object.keys(listsubCategory).length > 0 &&
        this.state.categoryID != ""
      ) {
        var filterSub = listsubCategory.filter(
          (x) => x.categoryID === this.state.categoryID
        );
        if (Object.keys(filterSub).length > 0) {
          optionResultSubCategory = listsubCategory.map((result) => {
            return <option value={result._id}>{result.subCategoryName}</option>;
          });
        } else {
          optionResultSubCategory = (
            <option value="">
              No SubCategory Found For Selected Category..
            </option>
          );
        }
      } else {
        optionResultSubCategory = (
          <option value="">No SubCategory Found...</option>
        );
      }
    }

    return (
      <div>
        <Sidebarmobile />
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            {/* begin:: Aside */}
            <Asidebar />
            {/* end:: Aside */}
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              {/* begin:: Header */}
              <div
                id="kt_header"
                className="kt-header kt-grid__item  kt-header--fixed "
              >
                {/* begin:: Header Menu */}
                <Header />
                {/* end:: Header Menu */}
                {/* begin:: Header Topbar */}
                <HeadeTopbar />
                {/* end:: Header Topbar */}
              </div>
              {/* end:: Header */}
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                {/* begin:: Subheader */}
                <SubHeader first="Home" second="Add Sub-Group Child" third="" />
                {/* end:: Subheader */}
                {/* begin:: Content */}
                <div
                  className="kt-content  kt-grid__item kt-grid__item--fluid"
                  id="kt_content"
                >
                  <div className="kt-portlet">
                    <div className="kt-portlet__head">
                      <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                          Add Sub-Group Child
                        </h3>
                      </div>
                    </div>
                    {/*begin::Form*/}
                    <form
                      className="kt-form kt-form--fit kt-form--label-right"
                      onSubmit={this.onSubmit}
                    >
                      <div className="kt-portlet__body">
                        <div className="form-group row">
                          <label className="col-lg-2 col-form-label">
                            Select Group:
                          </label>
                          <div className="col-lg-3">
                            <select
                              name="categoryID"
                              onChange={(e) => this.onChange(e)}
                              value={this.state.categoryID}
                              className="form-control"
                              placeholder=""
                            >
                              <option value="">Select Group</option>
                              {optionResult}
                            </select>
                            <span className="form-text text-danger">
                              {errors.categoryID}
                            </span>
                          </div>
                          <label className="col-lg-2 col-form-label">
                            Select Sub-Group:
                          </label>
                          <div className="col-lg-3">
                            <select
                              name="subcategoryID"
                              onChange={(e) => this.onChange(e)}
                              value={this.state.subcategoryID}
                              className="form-control"
                              placeholder=""
                            >
                              <option value="">Select Sub-Group</option>
                              {optionResultSubCategory}
                            </select>
                            <span className="form-text text-danger">
                              {errors.subcategoryID}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-2 col-form-label">
                            Sub-Group Child Name:
                          </label>
                          <div className="col-lg-3">
                            <input
                              type="text"
                              name="subCategoryChildName"
                              onChange={this.onChange}
                              value={this.state.subCategoryChildName}
                              className="form-control"
                              placeholder=""
                            />
                            <span className="form-text text-danger">
                              {errors.subCategoryChildName}
                            </span>
                          </div>
                          <label className="col-lg-2 col-form-label">
                            Upload Sub-Group Child Image:
                          </label>
                          <div className="col-lg-3">
                            <div className="kt-input-icon">
                              <input
                                type="file"
                                name="photoUrl"
                                onChange={this.uploadImage}
                                className="form-control"
                                placeholder=""
                              />
                              <span className="form-text text-danger">
                                {errors.photoUrl}
                              </span>
                            </div>
                            <span className="form-text text-success">
                              {this.state.uploadStatus}
                            </span>
                            <span className="form-text text-muted">
                              File Resolution (292px X 69px)
                            </span>
                          </div>
                        </div>

                        {/* discrption start */}

                        <div className="form-group row">
                          <label className="col-lg-2 col-form-label">
                            {" "}
                            Discription:
                          </label>
                          <div className="col-lg-8">
                            <textarea
                              name="content"
                              onChange={this.onChange}
                              value={this.state.content}
                              className="form-control"
                              placeholder=""
                            ></textarea>
                            <span className="form-text text-danger">{}</span>
                          </div>
                        </div>

                        {/* discrption end */}

                        {/* //new banar with img */}
                        <div className="form-group row">
                          <label className="col-lg-2 col-form-label">
                            Banner Visible:
                          </label>
                          <div className="col-lg-1">
                            <input
                              type="checkbox"
                              name="additionalContent"
                              onChange={this.onChange}
                              checked={this.state.additionalContent}
                              className="mt-3"
                            />
                            <span className="form-text text-danger">{}</span>
                          </div>
                        </div>

                        <div>
                          {additionalDetails.map((detail, index) => (
                            <div
                              key={index}
                              className="banar-box"
                              style={{
                                border: "1px solid black",
                                padding: "10px",
                                marginTop: "10px",
                              }}
                            >
                              <div>Banner Title: {detail.Title}</div>
                              <div>
                                Banner Description: {detail.Description}
                              </div>
                              <div>
                                <img src={detail.image} alt="Banner" />
                              </div>
                              <button
                                type="button"
                                onClick={() => this.removeDetail(index)}
                                className="btn btn-danger btn-sm mt-1"
                              >
                                <i class="fa fa-trash"></i>
                              </button>
                            </div>
                          ))}

                          <div
                            className="banar-box"
                            style={{
                              border: "1px solid black",
                              padding: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <div className="form-group row">
                              <label className="col-lg-2 col-form-label">
                                Banner Image:
                              </label>
                              <div className="col-lg-3">
                                <div className="kt-input-icon">
                                  <input
                                    type="file"
                                    name="bannerPhotoUrl"
                                    onChange={this.bannerUploadImage}
                                    className="form-control"
                                    placeholder=""
                                  />
                                  <span className="form-text text-danger">
                                    {errors.bannerPhotoUrl}
                                  </span>
                                </div>
                                <span className="form-text text-success">
                                  {this.state.bannerUploadStatus}
                                </span>
                                <span className="form-text text-muted">
                                  File Resolution (292px X 69px)
                                </span>
                              </div>
                              <label className="col-lg-2 col-form-label">
                                Banner Title:
                              </label>
                              <div className="col-lg-2">
                                <input
                                  type="text"
                                  name="bannerTitle"
                                  onChange={this.onChange}
                                  value={this.state.bannerTitle}
                                  className="form-control"
                                  placeholder=" "
                                />
                                <span className="form-text text-danger">
                                  {}
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-lg-2 col-form-label">
                                Banner Description:
                              </label>
                              <div className="col-lg-8">
                                <textarea
                                  name="banerContent"
                                  onChange={this.onChange}
                                  value={this.state.banerContent}
                                  className="form-control"
                                  placeholder={`<p className="image-paragraph-container"></p>`}
                                ></textarea>
                                <span className="form-text text-danger">
                                  {}
                                </span>
                              </div>
                              <div className="col-lg-2">
                                <button
                                  type="button"
                                  onClick={this.addDetail}
                                  className="btn btn-success btn-sm mt-1"
                                >
                                  <i class="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* //banar end */}

                        {/* faq qustion start */}
                        <div className="form-group row">
                          <label className="col-lg-2 col-form-label">
                            Faq Visible:
                          </label>
                          <div className="col-lg-1">
                            <input
                              type="checkbox"
                              name="faq"
                              onChange={this.onChange}
                              className="mt-3"
                            />
                            <span className="form-text text-danger">{}</span>
                          </div>
                        </div>

                        {faqList.map((detail, index) => (
                          <div
                            key={index}
                            className="banar-box"
                            style={{
                              border: "1px solid black",
                              padding: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <div>Faq Qustion: {detail.question}</div>
                            <div>Faq Answer: {detail.answer}</div>
                            <button
                              type="button"
                              onClick={() => this.removeFaqDetail(index)}
                              className="btn btn-danger btn-sm mt-1"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        ))}

                        <div
                          className="banar-box"
                          style={{
                            border: "1px solid black",
                            padding: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Faq Qustion:
                            </label>
                            <div className="col-lg-8">
                              <textarea
                                name="faqQustion"
                                onChange={this.onChange}
                                value={this.state.faqQustion}
                                className="form-control"
                                placeholder=""
                              ></textarea>
                              <span className="form-text text-danger">{}</span>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Faq Answer:
                            </label>
                            <div className="col-lg-8">
                              <textarea
                                name="faqAnswer"
                                onChange={this.onChange}
                                value={this.state.faqAnswer}
                                className="form-control"
                                placeholder=""
                              ></textarea>
                              <span className="form-text text-danger">{}</span>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <button
                              type="button"
                              onClick={this.addFaqDetail}
                              className="btn btn-success btn-sm mt-1"
                            >
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>

                        {/* faq question end  */}

                        <div className="form-group row mt-5">
                          <label className="col-lg-2 col-form-label">
                            isEnabled:
                          </label>
                          <div className="col-lg-3">
                            <select
                              name="isEnabled"
                              onChange={this.onChange}
                              value={this.state.isEnabled}
                              className="form-control"
                              placeholder=""
                            >
                              <option value="">Select isEnabled</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <span className="form-text text-danger">
                              {errors.isEnabled}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="kt-portlet__foot kt-portlet__foot--fit-x">
                        <div className="kt-form__actions">
                          <div className="row">
                            <div className="col-lg-3 d-flex justify-content-around">
                              <button
                                type="submit"
                                className={`btn btn-success ${
                                  subCategoryChildloading
                                    ? "kt-spinner kt-spinner--sm kt-spinner--light"
                                    : ""
                                }`}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                onClick={this.onReset}
                                className="btn btn-secondary"
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col-lg-10" />
                          </div>
                        </div>
                      </div>
                    </form>
                    {/*end::Form*/}
                  </div>

                  {/*end::Portlet*/}

                  {/*end::Portlet datatable*/}
                </div>
                {/* end:: Content */}
              </div>
              {/* begin:: Footer */}
              <Footer />
              {/* end:: Footer */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddSubCategoryChildChild.propTypes = {
  auth: PropTypes.object.isRequired,
  addSubCategoryChildChild: PropTypes.func.isRequired,
  listCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  subCategoryChild: state.subCategoryChild,
  subCategory: state.subCategory,
  category: state.category,
  store: state.store,
});

export default connect(mapStateToProps, {
  addSubCategoryChild,
  listCategory,
  listSubCategoryOne,
})(AddSubCategoryChildChild);
