import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import {
  attributePrice,
  createAttributeItems,
} from "../../../../actions/attributeAction";
import { productAttributes } from "../../../../actions/productAction";
import axios from "axios";
import "./helper.css";
import { bool } from "yup";
import FormComponents from "../../admin-draft-components/form-components/form-components";
import {API_URL} from '../../../../actions/constant'

let datas = [];
let queryString = "";
let imgPath = "http://ec2-3-239-208-80.compute-1.amazonaws.com:5000/static/";
let qString = "";
let list = [];

//New Code Function Starts

var cart_management_obj = {};
var is_noCatch = true;

//array object which stores for cart's link
var new_cart_arr_obj = [];

//To open existing modal for specific product variant
// const existingModal = (product_object, existing_values_arr, product_variant_quantity) => {
//   document.getElementById("modal_btn").click();

//   document.getElementById("Quantity_modal").value = product_variant_quantity;

//   Object.keys(product_object.metadata.attributeOptions).map( (key, idx) => {
//     document.getElementById( key+"__select" ).value = existing_values_arr[idx];
//     console.log("existingModal: ", key, );
//   } )

// }

// const modalDataCollector = (object) => {
//   //closing modal
//   document.querySelector("#exampleModal").click()

//     var modal_element = document.getElementById("modalDeployer-modalbody");
//     var new_sk_string = String(object.skuPrefix)
//     var total_price = parseFloat( object.price )
//     var selectedAttribute = []
//     var product_variant_quantity = null

//     //existing modal value for each attribute in array
//     var existing_values_arr = []

//     if (modal_element) {
//       let quantity = modal_element.getElementsByTagName("input")
//       let selects = modal_element.getElementsByTagName("select");

//       if (quantity) {
//         product_variant_quantity = quantity[0].value
//       }

//       console.log("selects: ", selects)
//       for (var i=0; i<selects.length;  i++) {

//         existing_values_arr.push( selects[i].value )

//         var element_arr = selects[i].value.split("&");

//         new_sk_string += "-"+String(element_arr[0]) //sku
//         total_price += parseFloat(element_arr[1])   //cost
//         selectedAttribute.push({
//           name: element_arr[3],
//           value: element_arr[2],
//           price: element_arr[1]
//         })

//         console.log("select: ", element_arr)
//       }
//       console.log("new_sk_string: ", new_sk_string, total_price)
//     }

//     //Producing cart object
//     var produced_cart_object = {
//       cartID: uuid(),
//       categoryID: object.categoryID,
//       id: object._id,
//       image: object.images ? object.images[0] : object.images,
//       name: object.name,
//       price: total_price,
//       quantity: product_variant_quantity,
//       selectedAttribute: selectedAttribute,
//       sku: new_sk_string,
//       skuPrefix: object.skuPrefix
//     }

//     //pushing the object in cart management. Structure { sku : cartObject }
//     cart_management_obj[new_sk_string] = produced_cart_object

//     //creating object to store cart objects
//     new_cart_arr_obj.push( produced_cart_object );

//     //adding after the Add New Variant Button
//     var element_appender = document.getElementById(object._id+"anv");
//     let btn = document.createElement("button");
//     btn.className = "btn btn-primary";
//     btn.onclick = () => existingModal(object, existing_values_arr, product_variant_quantity);
//     btn.innerHTML = new_sk_string + " : " + product_variant_quantity;
//     element_appender.append(btn)

//   console.log("cart_management_obj: ",cart_management_obj );
//   console.log("modalDataCollector cart link: ",new_cart_arr_obj );
//   console.log("modalDataCollector cart link: ", btoa( JSON.stringify(new_cart_arr_obj) ) );

// }

//New Code Function Ends

export default function Helper({
  product_object,
  product_object_state,
  set_product_object_state, //set State
  id,
  price,
  photo,
  name = "",
  email,
  submit,
  elementRef,
  setSubmit,
}) {
  const dispatch = useDispatch();

  const attributeItems = useSelector((state) => state.attributeItems);
  const { attributeList } = attributeItems;
  // console.log(attributeList, "attributeItems");

  //clearing cart according localStorage
  if (localStorage.getItem("clearCart")) {
    // cart_management_obj = {};
    localStorage.setItem("clearCart", false);

    //Clearing Temp Buttons
    // var elements = document.getElementsByClassName("createOrderTempButtons");
    // for (const idx in elements) {
    //   elements[idx].remove();
    // }

    //removes temp cart buttons
    // const elements = document.getElementsByClassName("createOrderTempButtons");
    // while(elements.length > 0){
    //     elements[0].parentNode.removeChild(elements[0]);
    // }

    console.log("Helper ls cart_management_obj: ", cart_management_obj);
  }

  const PRODUCT_OBJ = product_object;
  if (PRODUCT_OBJ)
    console.log("PRODUCT_OBJ", PRODUCT_OBJ.metadata.attributeOptions);

  if (product_object_state)
    console.log("product_object_state prod: ", product_object_state);

  name = name.split(" ")[0];

  const [dat, setDat] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [query, setQuery] = useState("");
  const [totalQuery, SetTotalQuery] = "";

  console.log("data state: ", dat);

  useEffect(() => {
    attributes.map((attribute) => {
      return (qString = `$&key=${attribute.key}&value=${attribute.mapValue}&price=${attribute.additionalPrice}`);
    });
    setQuery((prev) => prev + qString);
  }, [attributes]);

  console.log("query: ", query);
  useEffect(() => {
    const attri = async () => {
      const { data } = await axios.post(
        "http://ec2-3-239-208-80.compute-1.amazonaws.com:5000/api/product/attribute/",
        // "http://staging.zycaa.com:5000/api/product/attribute/",
        {
          productID: id,
        }
      );

      setDat(data);
    };
    attri();
  }, [id]);

  //To open existing modal for specific product variant
  const existingModal = (
    product_object,
    existing_values_arr,
    product_variant_quantity,
    object_key
  ) => {
    //setting state
    set_product_object_state(product_object);

    document.getElementById("modal_btn").click();

    document.getElementById("Quantity_modal").value = product_variant_quantity;

    Object.keys(product_object.metadata.attributeOptions).map((key, idx) => {
      console.log("existingModal: ", key, existing_values_arr);

      document.getElementById(key + "__select").value =
        existing_values_arr[idx];
    });

    //repeating it after a spcific milli seconds to update the modal child values
    setTimeout(() => {
      Object.keys(product_object.metadata.attributeOptions).map((key, idx) => {
        document.getElementById(key + "__select").value =
          existing_values_arr[idx];
      });
    }, 300);

    //setting up delete button
    var delete_btn = document.getElementById("modal_delete_existing");
    delete_btn.onclick = () => modalDeleteManager(object_key);
  };

  const modalDeleteManager = (object_key) => {
    //object_key is new_sku_string
    console.log("B modalDeleteManager: ", cart_management_obj);

    delete cart_management_obj[object_key];
    document.querySelector("#exampleModal").click();
    document.getElementById(object_key + "-for_delete_button").remove();

    console.log("A modalDeleteManager: ", cart_management_obj);
  };

  const modalDataCollector = (object) => {
    var modal_element = document.getElementById("modalDeployer-modalbody");
    var new_sk_string = String(object.skuPrefix);
    var total_price = parseFloat(object.price);
    var totalDiscount_price = parseFloat(object.discountPrice);
    var selectedAttribute = [];
    var product_variant_quantity = null;

    //existing modal value for each attribute in array
    var existing_values_arr = [];

    if (modal_element) {
      let quantity = modal_element.getElementsByTagName("input");
      let selects = modal_element.getElementsByTagName("select");

      if (quantity) {
        product_variant_quantity = quantity[0].value;
        if (product_variant_quantity < 1) {
          //validation
          window.alert("Oops! Enter valid quantity");
          return 0;
        }
      }

      console.log("selects: ", selects);
      for (var i = 0; i < selects.length; i++) {
        if (
          selects[i].value == "&&&" ||
          selects[i].value == "" ||
          selects[i].value == null
        ) {
          window.alert("Oops! Select valid attributes");
          return 0; //validation
        }

        existing_values_arr.push(selects[i].value);

        var element_arr = selects[i].value.split("&");

        new_sk_string += "-" + String(element_arr[0]); //sku
        total_price += parseFloat(element_arr[1]); //cost
        totalDiscount_price += parseFloat(element_arr[1]); //disount price
        selectedAttribute.push({
          name: element_arr[3],
          value: element_arr[2],
          price: element_arr[1],
        });

        console.log("select: ", element_arr);
      }
      console.log("new_sk_string: ", new_sk_string, total_price);
    }

    //Producing cart object
    var produced_cart_object = {
      cartID: uuid(),
      categoryID: object.categoryID,
      id: object._id,
      image: object.images ? object.images[0] : object.images,
      name: object.name,
      // price: total_price * product_variant_quantity, //commented this beacuse of quantity logic issue in main overtone cart
      price: total_price,
      basePrice: object.price,
      discountPrice: object.discountPrice,
      quantity: product_variant_quantity,
      selectedAttribute: selectedAttribute,
      sku: new_sk_string,
      skuPrefix: object.skuPrefix,
    };

    //closing modal
    document.querySelector("#exampleModal").click();

    //pushing the object in cart management. Structure { sku : cartObject }
    cart_management_obj[new_sk_string] = produced_cart_object;

    //creating object to store cart objects
    // new_cart_arr_obj.push( produced_cart_object );

    //deleting button if already exists for the sku-string
    var button_exist_check = document.getElementById(
      new_sk_string + "-for_delete_button"
    );
    if (button_exist_check) button_exist_check.remove();

    //adding after the Add New Variant Button
    var element_appender = document.getElementById(object._id + "anv");
    let btn = document.createElement("button");
    btn.id = new_sk_string + "-for_delete_button";
    btn.className = "btn createOrderTempButtons";
    btn.style.margin = "2px";
    btn.style.backgroundColor = "#f5f5f5";
    btn.onclick = () =>
      existingModal(
        object,
        existing_values_arr,
        product_variant_quantity,
        new_sk_string
      );
    btn.innerHTML =
      new_sk_string +
      " Qty: " +
      product_variant_quantity +
      " Price: <bold>$" +
      String(totalDiscount_price * product_variant_quantity) +
      "</bold> $<strike>" +
      String(total_price * product_variant_quantity) +
      "</strike>";
    element_appender.append(btn);

    //Payment Component Initialization
    paymentComponentCalculator();

    console.log("cart_management_obj: ", cart_management_obj);
    // console.log("modalDataCollector cart link: ",new_cart_arr_obj );
    // console.log("modalDataCollector cart link: ", btoa( JSON.stringify(new_cart_arr_obj) ) );
  };

  const paymentComponentCalculator = () => {
    var subTotal = 0;
    var disount = 0;
    var totalPrice = 0;
    Object.values(cart_management_obj).map((obj) => {
      subTotal += obj.price * obj.quantity;
      disount += (obj.basePrice - obj.discountPrice) * obj.quantity;
    });

    totalPrice = subTotal - disount;

    //mapping
    document.getElementById("payment___subtotal").innerHTML =
      "$" + String(subTotal);
    document.getElementById("payment___disount").innerHTML =
      "- $" + String(disount);
    document.getElementById("payment___totalPrice").innerHTML =
      "$" + String(totalPrice);
  };

  const onClickAttribute = async (value) => {
    let key = value.attributeName;
    let mapValue = value.mappingValue;
    let additionalPrice = value.additionalPrice;

    dispatch(attributePrice(additionalPrice));
    const newData = {
      key,
      mapValue,
      additionalPrice,
      parentKey: "",
    };
    dispatch(createAttributeItems(id, key, additionalPrice));

    if (attributes.find((val) => val.key == key)) {
      let index = attributes.map((idx) => idx.key).indexOf(key);
      let filtered = attributes.filter((idx) => idx.key != key);

      setAttributes(filtered);
    }

    setAttributes((state) => [...state, newData]);
    dispatch(productAttributes(newData));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  };

  const onChangeDropdown = async (value) => {
    let key = value.attributeName;
    let mapValue = value.mappingValue;
    let additionalPrice = value.additionalPrice;

    // if (attributes.find((val) => val.key == key)) {
    //   return;
    // }
    const newData = {
      key,
      mapValue,
      additionalPrice,
      parentKey: "",
    };

    dispatch(createAttributeItems(id, key, additionalPrice));
    if (attributes.find((val) => val.key == key)) {
      let index = attributes.map((idx) => idx.key).indexOf(key);
      let filtered = attributes.filter((idx) => idx.key != key);

      setAttributes(filtered);
    }
    setAttributes((state) => [...state, newData]);
    dispatch(productAttributes(newData));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let mail = "fahim1.618555@gmail.com";
    let result = `${id}&name=${name}&imgurl=${photo}&prodPrice=${price}&key=${key}&value=${mapValue}&price=${additionalPrice}`;
    // console.log(result);
    // if (submit) {
    if (false) {
      if (cart_management_obj == {}) {
        window.alert("Cart is empty! Please add items");
      } else {
        Object.values(cart_management_obj).map((value) => {
          temp_cart_arr_obj.push(value);
        });

        console.log(
          "temp_cart_arr_obj not: ",
          cart_management_obj,
          temp_cart_arr_obj
        );

        is_noCatch = true;
        try {
          await axios.post(
            // API_URL+`/api/orderemail/productId=${result}`,
           API_URL+ `/api/orderemail/${btoa(
              JSON.stringify(temp_cart_arr_obj)
            )}`,

            { email: email },
            config
          );

          // console.log("success");
        } catch (error) {
          console.error(error);
          is_noCatch = false;
        }

        // if (is_noCatch) {
        //   temp_cart_arr_obj = [];
        //   // window.alert("Success: An Email has been sent to the user")
        //   localStorage.setItem(email, true)  //new user turning out email sent for email to true in localstorage
        //   localStorage.setItem("clearCart", true); //clearing cart management object
        // }
      }
    }
  };
  const onColorClick = async (value) => {
    console.log("Helper Value: ", value);
    let key = value.attributeName;
    let mapValue = value.mappingValue;
    let additionalPrice = value.additionalPrice;

    const newData = {
      key,
      mapValue,
      additionalPrice,
      parentKey: "",
    };

    dispatch(createAttributeItems(id, key, additionalPrice));

    if (attributes.find((val) => val.key == key)) {
      let index = attributes.map((idx) => idx.key).indexOf(key);
      let filtered = attributes.filter((idx) => idx.key != key);

      setAttributes(filtered);
    }

    setAttributes((state) => [...state, newData]);
    dispatch(productAttributes(newData));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let mail = "fahim1.618555@gmail.com";
    let result = `${id}&name=${name}&imgurl=${photo}&prodPrice=${price}&key=${key}&value=${mapValue}&price=${additionalPrice}`;
  };
  // console.log(totalQuery);
  if (submit) {
    setSubmit(false); //disabling submit order button

    attributeList.map((item) => {
      if (list.find((idx) => idx == item.productId)) {
        console.log("matched");
        queryString += ``;
      }
      list.push(item.productId);
      console.log("matched", list);
      // SetTotalQuery((state) => state + (item.productId + item.type));
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const submitOrder = async () => {
      attributeList.map((item) => {});

      let result = `${id}&name=${name}&imgurl=${photo}&prodPrice=${price}${query}`;
      if (submit) {
        console.log("submit order: ", cart_management_obj);

        // if(cart_management_obj == {}) {
        if (Object.keys(cart_management_obj).length === 0) {
          window.alert("Cart is empty! Please add items");
          console.log("email not sent");
        } else {
          //Preparing cart array objects from the cart management object
          var temp_cart_arr_obj = [];
          Object.values(cart_management_obj).map((value) => {
            temp_cart_arr_obj.push(value);
          });

          console.log(
            "temp_cart_arr_obj: ",
            cart_management_obj,
            temp_cart_arr_obj,
            btoa(JSON.stringify(temp_cart_arr_obj))
          );
          console.log("Email Helper: ", email);
          try {
            // setSubmit(false); //disabling submit order button

            await axios.post(
              API_URL+`/api/orderemail/${btoa(
                JSON.stringify(temp_cart_arr_obj)
              )}`,

              {
                email: email,
                localStorage_temp: localStorage.getItem(email),
                userInfo: JSON.parse(
                  localStorage.getItem(String(email) + "_UserData")
                ),
              },
              config
            );
            // window.alert("An Email has been sent to the user");

            console.log("success");

            // commenting in impact of refreshing page
            // cart_management_obj = {} //clearing cart
            // temp_cart_arr_obj = []
            // localStorage.setItem(email, true)  //new user turning out email sent for email to true in localstorage
            // localStorage.setItem("clearCart", true); //clearing cart management object

            window.alert("Success: An Email has been sent to the user");

            setInterval(() => {
              window.location.href = "/admin/createorder"; //refreshing page to clear cart
            }, 2000);
          } catch (error) {
            console.error("Failed to send email", error);
            window.alert("Failed to send email");
          }

          if (is_noCatch) {
            //Preparing cart array objects from the cart management object
            // temp_cart_arr_obj = []
            // // window.alert("Success: An Email has been sent to the user")
            // localStorage.setItem(email, true)  //new user turning out email sent for email to true in localstorage
            // localStorage.setItem("clearCart", true); //clearing cart management object

            localStorage.setItem("clearCart", false);
            const elements = document.getElementsByClassName(
              "createOrderTempButtons"
            );
            while (elements.length > 0) {
              elements[0].parentNode.removeChild(elements[0]);
            }
          }
        } //else end
      }
    };
    submitOrder();
  }

  console.log(attributes);
  return (
    <div>
      <div>
        {/* here */}
        <button
          style={{ opacity: "0" }}
          id="modal_btn"
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          Launch demo modal
        </button>

        <div
          class="modal show"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Add Attributes
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                id="modalDeployer-modalbody"
                style={{ padding: "10px 25px" }}
                class="modal-body"
              >
                {/* New Code Starts */}
                {/* {console.log("nc code: ", product_object, product_object_state)} */}
                {/* {PRODUCT_OBJ ? <FormComponents ATTRUBUTE_OPTIONS={product_object.metadata.attributeOptions} /> : null} */}
                {product_object_state ? (
                  <FormComponents
                    key={product_object_state._id}
                    ATTRUBUTE_OPTIONS={
                      product_object_state.metadata.attributeOptions
                    }
                  />
                ) : null}
                {/* New Code Ends */}
              </div>
              {/*  */}
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  id="modal_delete_existing"
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                >
                  Delete
                </button>
                <button
                  type="button"
                  onClick={(e) =>
                    // document.querySelector("#exampleModal").click()
                    // modalDataCollector(PRODUCT_OBJ)
                    modalDataCollector(product_object_state)
                  }
                  class="btn btn-primary"
                >
                  Add/Update Cart
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* end */}
      </div>
    </div>
  );
}
