import {
  SPACETYPE_LOADING,
    ADD_SPACETYPE,
    EDIT_SPACETYPE,
    DELETE_SPACETYPE,
    LIST_SPACETYPE,
    SPACETYPE_STOPLOADING
  } from '../actions/types';
   
   const initialState = {
     addspacetype: null,
     spacetypeloading: false,
     editspacetype:null,
     deletespacetype:null,
     listspacetype:null,
   };
   
   export default function(state = initialState, action) {
     switch (action.type) {
       case SPACETYPE_LOADING:
         return {
           ...state,
           spacetypeloading: true
         };
         case SPACETYPE_STOPLOADING:
         return {
           ...state,
           spacetypeloading: false
         };
         
       case ADD_SPACETYPE:
         return {
           ...state,
           addspacetype: action.payload,
           spacetypeloading: false
        };
        case EDIT_SPACETYPE:
         return {
           ...state,
           editspacetype: action.payload,
           spacetypeloading: false
        };
        case LIST_SPACETYPE:
        return {
          ...state,
          listspacetype: action.payload,
          spacetypeloading: false
       };
       case DELETE_SPACETYPE:
        return {
          ...state,
          deletespacetype: action.payload,
          spacetypeloading: false
       }; 
       default:
         return state;
     }
   }
   