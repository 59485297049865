import axios from 'axios';
import {
  GET_ERRORS,
  LIST_WALLTYPE,
  EDIT_WALLTYPE,
  DELETE_WALLTYPE,
  WALLTYPE_LOADING,
  WALLTYPE_STOPLOADING,
  ADD_WALLTYPE,
} from './types';

// Get all slider
export const listWallType= () => dispatch => {
  dispatch(setWallTypeLoading());
  axios
    .get('/api/walltype')
    .then(res =>{
      console.log( "Wall Result",res.data)
      dispatch({
        type: LIST_WALLTYPE,
        payload:res.data
      })
    })
    .catch(err =>{
      console.log("err data",err)
      dispatch({
        type: GET_ERRORS,
        payload:err.response.data
      })

    });
};

// Create slider
export const addWallType= (wallData) => dispatch => {
    dispatch(setWallTypeLoading());
    axios
    .post('/api/walltype',wallData)
    .then(res =>
        dispatch({
            type: ADD_WALLTYPE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopWallTypeLoading());
    });
};

// Edit slider
export const editWalltype= (editWallTypeData) => dispatch => {
    dispatch(setWallTypeLoading());
    axios
    .put(`/api/walltype/${editWallTypeData._id}`,editWallTypeData)
    .then(res =>
        dispatch({
            type: EDIT_WALLTYPE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopWallTypeLoading());
    });
};
// delete slider
export const deleteWalltype= (deleteData) => dispatch => {
    dispatch(setWallTypeLoading());
    axios
    .delete(`/api/walltype/${deleteData._id}`,deleteData)
    .then(res =>
        dispatch({
            type: DELETE_WALLTYPE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopWallTypeLoading());
    });
};
// Slider loading
export const setWallTypeLoading = () => {
    return {
      type: WALLTYPE_LOADING
    };
  };
  export const stopWallTypeLoading = () => {
    return {
      type: WALLTYPE_STOPLOADING
    };
  };