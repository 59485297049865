import React from "react";

class FcDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownValue: "",
    };
  }

  componentDidMount() {
    var e = document.getElementById(this.props.object.label + "__select");
    console.log("eee: ", e.value);
    setTimeout(() => {
      console.log("eee1: ", e.value);
      if (e.value.split("&")[2])
        this.setState({ dropDownValue: String(e.value.split("&")[2]) });
    }, 200);
  }

  changeHandler = (e) =>
    this.setState({ dropDownValue: String(e.target.value.split("&")[2]) });

  render() {
    let object = this.props.object;

    return (
      <div
        key={object.label + "FcDropDown"}
        style={{
          backgroundColor: "#f5f5f5",
          padding: "10px 25px",
          borderRadius: "3px",
        }}
        class="form-group"
      >
        <label
          style={{ fontSize: "13px", color: "#555251" }}
          for={String(object.label) + "FormControlSelect1"}
        >
          {object.label}
        </label>
        <select
          id={String(object.label) + "__select"}
          style={{ marginBottom: "10px" }}
          class="form-control"
          onChange={(e) => this.changeHandler(e)}
        >
          <option value={"&&&"}>Choose</option>
          {object.options.__self__.length ? (
            object.options.__self__.map((val) => (
              <option
                key={val.skuPart}
                value={
                  val.skuPart +
                  "&" +
                  val.cost +
                  "&" +
                  val.label +
                  "&" +
                  object.label
                }
              >
                {val.label}
              </option>
            ))
          ) : (
            <option>No Options Available</option>
          )}
        </select>

        {/* for Childern Attributes */}
        {object.children.length
          ? object.children.map((each_obj) => (
              <div key={each_obj.label}>
                <label
                  style={{ fontSize: "13px", color: "#555251" }}
                  for={String(each_obj.label) + "FormControlSelect1"}
                >
                  {each_obj.label}
                </label>
                <select
                  id={String(each_obj.label) + "__select"}
                  style={{ marginBottom: "10px" }}
                  class="form-control"
                >
                  {object.options && this.state.dropDownValue != "" ? (
                    each_obj.options[
                      object.label + "/" + this.state.dropDownValue
                    ].map((val) => (
                      <option
                        key={val.skuPart}
                        value={
                          val.skuPart +
                          "&" +
                          val.cost +
                          "&" +
                          val.label +
                          "&" +
                          object.label
                        }
                      >
                        {val.label}
                      </option>
                    ))
                  ) : (
                    <option>Choose {object.label}</option>
                  )}
                </select>
              </div>
            ))
          : null}
      </div>
    );
  }
}

export default FcDropDown;
