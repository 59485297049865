import React, { useEffect, useState, useMemo, useRef } from "react";

import "./App.css";
import {
  listProduct,
  productId,
  searchProduct,
} from "../../../actions/productAction";
import { listUser, addUser } from "../../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { deleteAttributeItems } from "../../../actions/attributeAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
// import DemoModal from "./modals/DemoModal";
import Helper from "./modals/Helper";
import UserCreateModal from "./modals/UserCreateModal";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: true,
  theme: "dark",
  hideProgressBar: false,
  // timer: 6000,
});

let pid = [];
let prod;
let found;
let attributes;
let result = "";

const OrderCreateComponent = () => {
  const dispatch = useDispatch();

  const { listproduct } = useSelector((state) => state.product);
  const { listuser } = useSelector((state) => state.user);

  const { selectedAttribute } = useSelector((state) => state.product);
  const { attributeList } = useSelector((state) => state.attributeItems);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [mailing, setMailing] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [click, setClick] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [product, setProduct] = useState([]);
  const [query, setQuery] = useState("");
  const [productIds, setProductIds] = useState([]);
  const [userId, setUserId] = useState("");
  const [submit, setSubmit] = useState(false);
  const [productId, setProductId] = useState("");
  const [productSearchInput, SetProductSearchInput] = useState("");
  const [showUser, setShowUser] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);

  //new Code
  const [helper_product_state, setHelper_product_state] = useState(null);

  // const elementRef = useRef();

  const imgBaseURL = "/static/";

  // console.log(click);

  useEffect(() => {
    dispatch(listProduct());
    dispatch(listUser());
    // document.getElementById("modal_btn").click();
    // elementRef.current.click();
  }, []);

  /* Function to generate combination of password */
  const generateP = () => {
    var pass = "";
    var str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";

    for (let i = 1; i <= 8; i++) {
      var char = Math.floor(Math.random() * str.length + 1);

      pass += str.charAt(char);
    }

    return pass;
  };

  const createUserHandler = async (e) => {
    let generated_password = generateP();
    e.preventDefault();
    const userData = {
      name: username,
      email: email,
      mobile: mobile,
      // password: password,
      // password2: password,
      password: generated_password,
      password2: generated_password,
    };
    console.log("userData occ: ", userData);
    // console.log(userData);
    await dispatch(addUser(userData));

    //closing modal
    document.querySelector("#createNewUser_Modal").click();

    document.getElementById("createNewUserForm").reset(); //reseting form

    //creating flag for new user created
    // console.log("lc email: ", localStorage.getItem(email));
    localStorage.setItem(email, false);
    localStorage.setItem(String(email) + "_UserData", JSON.stringify(userData));
    // console.log("alc email: ", localStorage.getItem(email));

    // Toast.fire({
    //   type: "success",
    //   title: "An New user has been created",
    // });
  };

  // Product Selection and filtering
  // let subtotal = 0;
  // let discount = 0;
  console.log("listproduct", listproduct);

  const addProductHandler = (id) => {
    found = pid.find((_id) => _id == id);
    found = productIds.find((_id) => _id == id);
    if (found) {
      setProductIds((item) => item.filter((p) => p !== id));
      pid = pid.filter((p) => p !== id);
      prod = listproduct.find((prod) => prod._id == id);

      setSubtotal((prev) => prev - Number(prod.price));
      setDiscount((prev) => prev - Number(prod.discountPrice));
      dispatch(deleteAttributeItems(id));
      return;
    } else {
      setProductIds((state) => [...state, id]);
      pid.push(id);
      prod = listproduct.find((prod) => prod._id == id);

      // modal open

      document.getElementById("modal_btn").click();

      //updating state for helper
      setHelper_product_state(prod);
      console.log("setHelper_product_state prod: ", helper_product_state);

      // console.log(prod);
      console.log("prod: ", prod);

      // setProductId(id);
      setProductId(prod); //passed product object instead of id to helper.js !!!!!!!!!!!!!Important

      setProduct(prod);
      setSubtotal((prev) => prev + Number(prod.price));
      setDiscount((prev) => prev + Number(prod.discountPrice));
      // dispatch(productId(id));
    }
  };

  // product total price

  // console.log(userId);

  const userSelectHandler = (userId) => {
    let mail = listuser.find((user) => {
      if (user._id == userId) {
        return user.email;
      }
    }).email;

    // console.log(mail);
    setUserId(userId);

    setMailing(mail);
  };

  const submitOrder = async () => {
    if (userId.length > 0) {
      setSubmit(true);

      // window.alert("Success: An Email has been sent to the user")
      // Toast.fire({
      //   type: "success",
      //   title: "An Email has been sent to the user",
      // });
      //localStorage.setItem(email, true)  //new user turning out email sent for email to true in localstorage

      //clearing cart management object
      // localStorage.setItem("clearCart", true);
      return;
    }
    setSubmit(false);
    Toast.fire({
      type: "error",
      title: "Please select a user or product",
    });
  };

  const searchProductHandler = (e) => {
    e.preventDefault();
    dispatch(searchProduct(productSearchInput));
  };

  const handleUserDropdown = () => {
    setShowUser(true);
  };
  return (
    <React.Fragment>
      {console.log("OrderCreate render prod:")}
      <Helper
        product_object={prod}
        product_object_state={helper_product_state}
        set_product_object_state={setHelper_product_state}
        submit={submit}
        email={mailing}
        id={productId}
        price={subtotal}
        name={product.name}
        photo={product.photoUrl1}
        setSubmit={setSubmit}
      ></Helper>
      <div
        // onClick={() => setShowUser(false)}
        id="save-click"
        className="body container my-4 "
      >
        <div className="row row-1">
          <div className="col dis_flex">
            <h4>Admin Create Order</h4>
            <button
              onClick={submitOrder}
              className="btn btn-info create_order_submit_btn"
            >
              Submit Order
            </button>
          </div>
        </div>

        <div className="row mt-4 dropdown_container">
          <div className="body-container-1 col-lg-7">
            <div style={{ paddingBottom: "25px" }} className="products">
              <div className="pt-4">
                <h4>Products</h4>

                <form
                  onSubmit={searchProductHandler}
                  className="input-group mb-3 input-field"
                >
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fas fa-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Products"
                    aria-label="Products"
                    aria-describedby="basic-addon1"
                    onChange={(e) => SetProductSearchInput(e.target.value)}
                  />
                  <button
                    className="btn btn-outline-secondary ml-2"
                    type="button"
                  >
                    Browse
                  </button>
                </form>
              </div>
            </div>
          </div>
          {/* After Selecting a customer */}
          {/* <div className='customer_information_container'>
            <div className='customer_info_heading'>
              <div className='customer_info_title'>Customer</div>
              <i className='fas fa-times'></i>
            </div>
            <div className='contact_info_email_container'>
              <div className='contact_info_email_heading'>
                <div className='contact_info_title'>CONTACT INFORMATION</div>
              </div>
              <span className='contact_info_email'>
                {" "}
                fahim1.618555@gmail.com
              </span>
            </div>
          </div> */}
          {/* Before selecting a user */}
          <div className="col-lg-5 pt-4 order_user_list_container">
            <h4>Find or create a customer</h4>
            <div className="input-group user_search_input_container mb-3">
              <span className="input-group-text" id="basic-addon1">
                <i className="fas fa-search"></i>
              </span>
              <input
                onMouseLeave={() => setShowUser(false)}
                onClick={handleUserDropdown}
                type="text"
                className="form-control"
                placeholder="Search User"
                aria-label="Email"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: "0" }} className="row row-2">
          <div className="col-lg-7">
            <div className="payment ">
              <table
                className="table table-striped- table-bordered table-hover table-checkable  mb-0"
                id="kt_table_1"
              >
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Base Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {listproduct &&
                    listproduct.map((product, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <img
                              style={{ backgroundSize: "cover" }}
                              width={50}
                              height={50}
                              src={product.images}
                              alt={product.name}
                            />
                            <span className="pl-3">{product.name}</span>
                          </td>

                          <td>${product.price}</td>

                          <td id={product._id + "anv"}>
                            <button
                              className="btn btn-dark"
                              onClick={() => addProductHandler(product._id)}
                            >
                              Add Variant
                            </button>
                            <hr style={{ borderTopColor: "#7f7d7c" }}></hr>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr className="mt-2 pt-4">
                    <th>Product</th>
                    <th>Base Price</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "700px",
              overflow: "scroll",
            }}
            className="col-lg-5 pt-4 order_user_list_container"
          >
            {/* {showUser ? ( */}
            <div
              onMouseEnter={() => setShowUser(true)}
              className="order_create_user_dropdown"
            >
              {/* <!-- Button as div trigger create new user modal --> */}
              <div
                className="user_create_container"
                data-toggle="modal"
                data-target="#createNewUser_Modal"
              >
                <i className="far fa-plus-square"></i>
                <span className="user_create_text">Create a new customer</span>
              </div>
            </div>
            {/* //  ) : null}  */}

            {/* <h4>User List</h4> */}
            <div className="input-group mb-3"></div>
            <table
              className="table table-striped- table-bordered table-hover table-checkable"
              id="kt_table_1"
            >
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Email</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {listuser &&
                  listuser.map((user, idx) => (
                    <tr key={idx}>
                      <td>
                        <input
                          onClick={() => userSelectHandler(user._id)}
                          type="radio"
                          id="html"
                          name="fav_language"
                          value="HTML"
                        ></input>
                      </td>

                      <td>{user.email}</td>
                      <td>{user.name}</td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>Action</th>
                  <th>Email</th>
                  <th>Name</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="row row-2">
          <div className="col-lg-7">
            <div className="payment pt-4">
              <h4>Payment</h4>
              <table className="payment-table">
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <b>Subtotal</b>
                      </p>
                    </td>
                    <td id="payment___subtotal">
                      $0
                      {/* {attributeList.length > 0 ? (
                        <p>
                          $
                          {Number(
                            attributeList.reduce(
                              (acc, item) => Number(acc) + Number(item.price),
                              0
                            )
                          )}
                        </p>
                      ) : (
                        <p>${subtotal}</p>
                      )} */}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Discount</p>
                    </td>
                    <td>
                      {/* <p>${discount}</p> */}
                      <p id="payment___disount">$0</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Add shipping</p>
                    </td>
                    <td>
                      <p>$0.0</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Tax</p>
                    </td>
                    <td>
                      <p>$0.0</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <b>Total</b>
                      </p>
                    </td>
                    <td id="payment___totalPrice">
                      $0
                      {/* {attributeList.length > 0 ? (
                        <p>
                          $
                          {Number(
                            attributeList.reduce(
                              (acc, item) => Number(acc) + Number(item.price),
                              Number(discount)
                            )
                            // .slice(-1)
                            // .slice()
                          )}
                        </p>
                      ) : (
                        <p>${subtotal + discount}</p>
                      )} */}
                      {/* <p>${subtotal + discount}</p> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-5 p-0">
            {/* Modal Start */}
            <div
              class="modal fade"
              id="createNewUser_Modal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Create New User
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div
                    class="modal-body"
                    style={{ padding: "10px 25px", backgroundColor: "#f5f5f5" }}
                  >
                    {/* Create new user form   */}
                    <div
                      className="order_list_create_user_contianer pt-4"
                      style={{
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <form id="createNewUserForm" onSubmit={createUserHandler}>
                        <div className="form_input_flex form-group">
                          <h6>Email</h6>
                          <input
                            className="form-control"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            type="email"
                            required
                          />
                        </div>
                        {/* <div className='form_input_flex form-group'>
                        <h6>Password</h6>
                        <input className="form-control"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          type='password'
                          required
                        />
                      </div> */}
                        <div className="form_input_flex form-group">
                          <h6>Username</h6>
                          <input
                            className="form-control"
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                            type="text"
                            required
                          />
                        </div>
                        <div className="form_input_flex form-group">
                          <h6>Mobile</h6>
                          <input
                            className="form-control"
                            onChange={(e) => setMobile(e.target.value)}
                            value={mobile}
                            type="tel"
                            required
                          />
                        </div>
                        <div
                          style={{
                            float: "right",
                            padding: "0px 0px 10px 0px",
                          }}
                        >
                          <button
                            type="button"
                            class="btn btn-secondary"
                            style={{ background: "white" }}
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            style={{ margin: "0px 20px" }}
                            className="order_user_create_submit_btn btn btn-primary"
                            type="submit"
                          >
                            Create
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Modal End */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderCreateComponent;
