import axios from 'axios';
import {
  EXISTINGSURFACE_LOADING,
  EXISTINGSURFACE_STOPLOADING,
  ADD_EXISTINGSURFACE,
  EDIT_EXISTINGSURFACE,
  DELETE_EXISTINGSURFACE,
  LIST_EXISTINGSURFACE,
  GET_ERRORS,
} from './types';

//List Existing Surface
export const listExistingSurface= () => dispatch => {
  dispatch(setExistingSurfaceLoading());
  axios
    .get('/api/existingsurface')
    .then(res =>{
      console.log("listexistingsurface", res);
      dispatch({
        type: LIST_EXISTINGSURFACE,
        payload:res.data
      })
    })
    .catch(err =>{
      console.log("err data",err)
      dispatch({
        type: GET_ERRORS,
        payload:err.response.data
      })

    });
};

// add existing surface
export const addExistingSurface= (existingSurfaceData) => dispatch => {
    dispatch(setExistingSurfaceLoading());
    axios
    .post('/api/existingsurface',existingSurfaceData)
    .then(res =>
        dispatch({
            type: ADD_EXISTINGSURFACE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopExistingSurfaceLoading());
    });
};

// Edit existing surface
export const editExistingSurface= (editExistingSurfaceData) => dispatch => {
    dispatch(setExistingSurfaceLoading());
    axios
    .put(`/api/existingsurface/${editExistingSurfaceData?._id}`,editExistingSurfaceData)
    .then(res =>
        dispatch({
            type: EDIT_EXISTINGSURFACE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopExistingSurfaceLoading());
    });
};
// delete existing surface
export const deleteExistingSurface= (deleteData) => dispatch => {
    dispatch(setExistingSurfaceLoading());
    axios
    .delete(`/api/existingsurface/${deleteData._id}`,deleteData)
    .then(res =>
        dispatch({
            type: DELETE_EXISTINGSURFACE,
            payload:res.data
        })
    )
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
        dispatch(stopExistingSurfaceLoading());
    });
};
// existing surface loading
export const setExistingSurfaceLoading = () => {
    return {
      type: EXISTINGSURFACE_LOADING
    };
  };
  export const stopExistingSurfaceLoading = () => {
    return {
      type: EXISTINGSURFACE_STOPLOADING
    };
  };