import React from "react";
import FcDropDown from "./fc-dropdown.components";

class FormComponents extends React.Component {
  constructor(props) {
    super(props);
    this.attributeOptions = this.props.ATTRUBUTE_OPTIONS;
    this.state = {
      parent_fields: [],
      childern_fields: {}, //Structure { parent_label : childern_object, ... }

      quantity: 0,
    };
  }

  componentDidMount() {
    this.attributeSetup();
  }

  attributeSetup = () => {
    var parent_fields = [];
    var childern_fields = {};

    for (const dynamic_object in this.attributeOptions) {
      //Pushing parent objects in state
      if (String(this.attributeOptions[dynamic_object].parent) == "null")
        parent_fields.push(this.attributeOptions[dynamic_object]);

      //Pushing child objects in state
      if (
        String(this.attributeOptions[dynamic_object].parentLabel) != "undefined"
      )
        childern_fields[this.attributeOptions[dynamic_object].parentLabel] =
          this.attributeOptions[dynamic_object];
    }

    console.log("state: ", parent_fields);
    console.log("state c: ", childern_fields);

    this.setState({
      parent_fields: parent_fields,
      childern_fields: childern_fields,
    });
  };

  handleChange = (e, dynamic_variable) => {
    this.setState({ [dynamic_variable]: e.target.value });
  };

  render() {
    return (
      <div>
        <h1 style={{ marginBottom: "10px", color: "#3f3d3b" }}>Quantity</h1>
        <input
          id="Quantity_modal"
          value={this.state.quantity}
          onChange={(e) => this.handleChange(e, "quantity")}
          style={{ marginBottom: "10px" }}
          class="form-control"
          type="number"
          placeholder="Enter Quantity"
        />

        {this.state.parent_fields.map((object) => (
          <div key={object.label}>
            <hr style={{ borderTopColor: "#808080" }}></hr>

            {/* Parent Attribute Label & Dropdown */}
            <h1 style={{ marginBottom: "10px", color: "#3f3d3b" }}>
              {object.label}
            </h1>
            <FcDropDown object={object} />
          </div>
        ))}
      </div>
    );
  }
}

export default FormComponents;
