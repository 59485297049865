import {
  ADD_WALLTYPE,
  DELETE_WALLTYPE,
  EDIT_WALLTYPE,
  LIST_WALLTYPE,
  WALLTYPE_LOADING,
  WALLTYPE_STOPLOADING,
} from "../actions/types";

const initialState = {
  addwalltype: null,
  walltypeloading: false,
  editwalltypeRec: null,
  deletewalltypeRec: null,
  listwalltypeRec: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case WALLTYPE_LOADING:
      return {
        ...state,
        walltypeloading: true,
      };
    case WALLTYPE_STOPLOADING:
      return {
        ...state,
        walltypeloading: false,
      };

    case ADD_WALLTYPE:
      return {
        ...state,
        addwalltype: action.payload,
        walltypeloading: false,
      };
    case EDIT_WALLTYPE:
      return {
        ...state,
        editwalltypeRec: action.payload,
        walltypeloading: false,
      };
    case LIST_WALLTYPE:
      return {
        ...state,
        listwalltypeRec: action.payload,
        walltypeloading: false,
      };
    case DELETE_WALLTYPE:
      return {
        ...state,
        deletewalltypeRec: action.payload,
        walltypeloading: false,
      };
    default:
      return state;
  }
}
