

// OA TEST HTTPS
export const API_URL = 'https://uat.admin.towerfour.net';
export const IMAGE_URL = 'https://uat.admin.towerfour.net/static/';
export const SUCCESS_URL = 'https://uat.admin.towerfour.net/paymentsuccess';
export const FAILURE_URL = 'https://uat.admin.towerfour.net/paymentfailed';
export const PRODUCT_API_URL = 'https://uat.product.api.towerfour.net';

// OA Localhost
// export const API_URL = 'http://localhost:5000';
// export const IMAGE_URL = 'http://localhost:5000/static/';
// export const SUCCESS_URL = 'http://localhost:5000/paymentsuccess';
// export const FAILURE_URL = 'http://localhost:5000/paymentfailed';
// export const PRODUCT_API_URL = 'http://localhost:3030';

//GOOGLE_CLIENT_ID
export const YOUR_GOOGLE_CLIENT_ID = "445476180873-cjhla1gkg1di78v6jn4em7bbicgbsjh5.apps.googleusercontent.com";
//FACEBOOK_App_ID
export const YOUR_FACEBOOK_APP_ID = "631270835698219";

export const PAYMENT_URL =
	'https://apps.bookeey.com/pgapi/api/payment/requestLink';
export const PAYMENT_STATUS_URL =
	'https://apps.bookeey.com/pgapi/api/payment/paymentstatus';

export const groupBy = (objectArray, property) => {
	return objectArray.reduce((acc, obj) => {
		const key = obj[property];
		if (!acc[key]) {
			acc[key] = [];
		}
		// Add object to list for given key's value
		acc[key].push(obj);
		return acc;
	}, {});
};

export const multigroupBy = ({ Group: array, By: props }) => {
	const getGroupedItems = (item) => {
		var returnArray = [];
		let i;
		for (i = 0; i < props.length; i++) {
			returnArray.push(item[props[i]]);
		}
		return returnArray;
	};

	let groups = {};
	let i;

	for (i = 0; i < array.length; i++) {
		const arrayRecord = array[i];
		const group = JSON.stringify(getGroupedItems(arrayRecord));
		groups[group] = groups[group] || [];
		groups[group].push(arrayRecord);
	}
	return Object.keys(groups).map((group) => {
		return groups[group];
	});
};
